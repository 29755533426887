<template>
  <div id="resetpwd">
    <!-- <h1>这是重置密码组件</h1> -->
    <el-breadcrumb>
      <el-breadcrumb-item :class="'active'">{{$t('m.loginandregister.reset_password')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div id="input">
      <div id="pwdinput">
        <span class="name">{{$t('m.loginandregister.Set_Password')}}</span>
        <el-input maxlength="16"
        @blur="verify(inputvalue.pwdinput,'password')"
        v-model="inputvalue.pwdinput" show-password :placeholder="$t('m.loginandregister.Please_set_let_num')" />
        <!-- <i class="icon"></i> -->
      </div>
      <div id="confirmpwd_input">
        <span class="name">{{$t('m.loginandregister.Confirm_Password')}}</span>
        <el-input maxlength="16"
        @blur="verify(inputvalue.pwdinput,inputvalue.confirmpwd_input,'confirmpwd_input')"
        v-model="inputvalue.confirmpwd_input" show-password :placeholder="$t('m.loginandregister.Please_set_let_num')" />
      </div>
      <div id="sub">
          <el-button style="color: #ffffff"
          native-type="submit"
          @click="tologin"
          >{{$t('m.loginandregister.confirm')}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import LogRegMain from './LogRegMain'

// import tomessage from '../../tools/tomessage.js'
// import emitter from '../../tools/bus'
import { ElMessage } from 'element-plus'
import { getStore } from '../../tools/storage'
import http from '../../api/http'
import i18n from '@/tools/i18n.js'

// // 校验验证码，返回用户类型
const forgetPassword = (data) => {
  return http.post('/mall-portal/sso/forgetPassword', data, false)
}
export default {
  name: 'ResetPwd',
  data () {
    return {

    }
  },
  methods: {
  },
  created () {
  },
  setup (props, context) {
    const { t } = i18n.global
    const inputvalue = reactive({
      pwdinput: '',
      confirmpwd_input: ''
    })
    const commitfather = (pageName) => {
      context.emit('topage', pageName)
    }
    onMounted(() => {

    })
    const tologin = async () => {
      // 获取到找回密码的手机号
      if (getStore('retPhone')) {
        const retphone = JSON.parse(getStore('retPhone'))
        console.log(retphone)
        if (/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(retphone.phoneinput)) {
          //
          if (inputvalue.pwdinput !== inputvalue.confirmpwd_input) {
            ElMessage.error({
              message: t('m.loginandregister.Two_passwords')
            })
            return
          }
          if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/.test(inputvalue.pwdinput)) {
            ElMessage.error({
              message: '请正确填写密码（含有数字和字母，且长度要在8-16位之间）'
            })
            return
          }
          const data = {
            telephone: retphone.phoneinput,
            password: inputvalue.pwdinput,
            authCode: retphone.codeinput
          }
          const res = await forgetPassword(data)
          console.log(res)
          if (res.data.msg === '验证失败，请重新发送验证码') {
            commitfather('RetrievePwd')
            ElMessage.warning({
              message: res.data.msg
            })
          } else if (res.data.msg === '验证码错误') {
            commitfather('RetrievePwd')
            ElMessage.warning({
              message: res.data.msg
            })
          } else if (res.data.code === 200) {
            ElMessage.success({
              message: res.data.msg
            })
            commitfather('IDLogin')
          }
        } else {
          ElMessage({
            duration: 1000,
            showClose: true,
            message: t('m.loginandregister.phonenumber_incorrect'),
            type: 'warning'
          })
          commitfather('RetrievePwd')
        }
      }
      // commitfather('IDLogin')
    }
    // 把数据传给数据处理方法LogRegMian
    const verify = (value, type, confirmpwd) => {
      if (confirmpwd === 'confirmpwd_input') {
        LogRegMain.setmessage.ConfirmPwd(value, type)
      } else {
        LogRegMain.setmessage.Password(value)
      }
    }
    return {
      inputvalue, commitfather, tologin, verify
    }
  }
}
</script>

<style lang="scss">
@import '../../style/reglog.scss';
@import '../../style/base.css';
#resetpwd{
  // background: palegreen;
  height: 320px;
  box-sizing: border-box;
  margin: 40px;
  display: flex;
  flex-wrap: wrap;
  .el-breadcrumb{
    padding: 8px 40px;
    width: 100%;
    .el-breadcrumb__item{
      text-align: center;
      width: 100%;
      .el-breadcrumb__inner{
        color: #999999;
        font-size: 20px;
        // cursor: pointer;
      }
      .el-breadcrumb__separator{
        // display: block;
        margin:0 35px;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .active{
      .el-breadcrumb__inner{
        color: #333333;
      }
    }
  }
  #input{
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
    #pwdinput,#confirmpwd_input{
      margin: 10px 0;
      height: 30px;
      line-height: 30px;
      width: 100%;
      display: flex;
      border-bottom: 1px solid #EAEAEA;
      .name{
        height: 30px;
        line-height: 30px;
        min-width: 80px;
        font-size: 14px;
        flex-shrink: 0;
      }
      .el-input{
        .el-input__suffix{
          .el-input__suffix-inner{
            .el-input__icon{
              color: #333333;
            }
          }
        }
        .el-input-group__append{
          border: 0;
          background: none;
          .el-button--warning{
            padding:0;
            height: 30px;
            color: #333333;
            text-align: right;
            span{
              display: inline-block;
              padding-left: 20px;
              border-left: #999999 1px solid;
            }
          }
        }
      }
      input{
        height: 30px;
        flex: 1;
      }
      .icon{
        border: 1px solid black;
        margin: 0 10px;
        width: 30px;
      }
    }
    #sub{
      width: 100%;
      margin: 30px 0;
      button{
        width: 100%;
        height: 40px;
        background: linear-gradient(0deg, #280E01 0%, #673000 100%);
        span{
          font-weight: 400;
        }
      }
    }
  }
  #toother{
    display: flex;
    width: 100%;
    text-align: center;
    span{
      flex: 1;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #C6824E;
    }
  }
}

</style>
