<template>
  <div id="retrievepwd">
    <!-- <h1>这是找回密码组件</h1> -->
    <el-breadcrumb>
      <el-breadcrumb-item :class="'active'">{{$t('m.loginandregister.Retrieve_password')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div id="input">
      <div id="idinput">
        <span class="name">{{$t('m.loginandregister.phone_number')}}</span>
        <el-input
        @blur="verify(inputvalue.phoneinput,'phone')"
        v-model="inputvalue.phoneinput" :placeholder="$t('m.loginandregister.Please_enter_phone_number')">
          <template #append>
            <el-button type="warning" size="small" :loading="checkCodeBtn.loading"
            :disabled="checkCodeBtn.disabled"
            @click="getCheckCode">
            {{checkCodeBtn.text}}</el-button>
            </template>
        </el-input>
      </div>
      <div id="codeinput">
        <span class="name">{{$t('m.loginandregister.auth_code')}}</span>
        <el-input v-model="inputvalue.codeinput" maxlength="6" :placeholder="$t('m.loginandregister.Please_enter_auth_code')" />
        <!-- <i class="icon"></i> -->
      </div>
      <div id="sub">
          <el-button style="color: #ffffff"
          native-type="submit"
          @click="tonext"
          >{{$t('m.loginandregister.next_step')}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
import LogRegMain from './LogRegMain'

// import emitter from '../../tools/bus.js'
import http from '../../api/http'
import { ElMessage } from 'element-plus'
import { setStore } from '../../tools/storage'
import i18n from '@/tools/i18n.js'
// import { useRouter } from 'vue-router'

export default {
  name: 'RetrievePwd',
  data () {
    return {

    }
  },
  methods: {
  },
  setup (props, context) {
    // const datcop = getCurrentInstance()
    const { t } = i18n.global
    // const router = useRouter()
    const inputvalue = reactive({
      phoneinput: '',
      codeinput: ''
    })
    // 验证码获取倒计时操作
    const checkCodeBtn = reactive({
      text: t('m.loginandregister.TestGetCode'),
      loading: false,
      disabled: false,
      duration: 60,
      timer: null
    })
    // 获取修改密码验证码
    const getCheckCode = () => {
      // console.log(inputvalue.phoneinput)
      if (!/^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(inputvalue.phoneinput)) {
        ElMessage.warning({
          message: '手机号码可能不正确'
        })
        return null
      }
      http.get('/mall-portal/sso/getAuthCode', {
        telephone: String(inputvalue.phoneinput),
        flag: 1,
        type: 'null'
      })
        .then(res => {
          console.log(res)
          if (res.data.code === 200) {
            ElMessage.success({ message: res.data.message })
          }
          if (res.data.message === '该手机已注册过了') {
            clearInterval(checkCodeBtn.timer)
          }
          if (res.data.code === 500) {
            ElMessage.error({ message: res.data.message })
          }
        }).catch(err => {
          console.log(err)
        })
      // 倒计时期间按钮不能单击
      if (checkCodeBtn.duration !== 60) {
        checkCodeBtn.disabled = true
      }
      // 清除掉定时器
      checkCodeBtn.timer && clearInterval(checkCodeBtn.timer)
      // 开启定时器
      checkCodeBtn.timer = setInterval(() => {
        const tmp = checkCodeBtn.duration--
        checkCodeBtn.text = `${tmp}${t('m.loginandregister.second')}`
        if (tmp <= 0) {
          // 清除掉定时器
          clearInterval(checkCodeBtn.timer)
          checkCodeBtn.duration = 60
          checkCodeBtn.text = t('m.loginandregister.regain')
          // 设置按钮可以单击
          checkCodeBtn.disabled = false
        }
        // console.log(checkCodeBtn.duration)
      }, 1000)
    }
    const commitfather = (pageName) => {
      context.emit('topage', pageName)
    }
    // 后期返回验证码验证进行下一步操作
    const tonext = async () => {
      // emitter.emit('retPhone', inputvalue)
      setStore('retPhone', inputvalue)
      commitfather('ResetPwd')
    }
    // 把数据传给数据处理方法LogRegMian
    const verify = (value, type) => {
      if (type === 'phone') {
        LogRegMain.setmessage.Phone(value)
      } else {
        console.error('找不到匹配内容')
      }
    }
    return {
      inputvalue, checkCodeBtn, commitfather, getCheckCode, tonext, verify
    }
  }
}
</script>

<style lang="scss">
@import '../../style/reglog.scss';
@import '../../style/base.css';
#retrievepwd{
  // background: palegreen;
  height: 320px;
  box-sizing: border-box;
  margin: 40px;
  display: flex;
  flex-wrap: wrap;
  .el-breadcrumb{
    padding: 8px 40px;
    width: 100%;
    .el-breadcrumb__item{
      text-align: center;
      width: 100%;
      .el-breadcrumb__inner{
        color: #999999;
        font-size: 20px;
        // cursor: pointer;
      }
      .el-breadcrumb__separator{
        // display: block;
        margin:0 35px;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .active{
      .el-breadcrumb__inner{
        color: #333333;
      }
    }
  }
  #input{
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
    #idinput,#codeinput,#pwdinput,#confirmpwd_input{
      margin: 10px 0;
      height: 30px;
      line-height: 30px;
      width: 100%;
      display: flex;
      border-bottom: 1px solid #EAEAEA;
      .name{
        height: 30px;
        line-height: 30px;
        min-width: 80px;
        font-size: 14px;
        flex-shrink: 0;
      }
      .el-input{
        .el-input__suffix{
          .el-input__suffix-inner{
            .el-input__icon{
              color: #333333;
            }
          }
        }
        .el-input-group__append{
          border: 0;
          background: none;
          .el-button--warning{
            padding:0;
            height: 30px;
            color: #333333;
            text-align: right;
            span{
              text-align: center;
              display: inline-block;
              padding: 0 18px;
              width: 70px;
              border-left: #999999 1px solid;
            }
          }
        }
      }
      input{
        height: 30px;
        flex: 1;
      }
      .icon{
        border: 1px solid black;
        margin: 0 10px;
        width: 30px;
      }
    }
    #sub{
      width: 100%;
      margin: 30px 0;
      button{
        width: 100%;
        height: 40px;
        background: linear-gradient(0deg, #280E01 0%, #673000 100%);
        span{
          font-weight: 400;
        }
      }
    }
  }
  #toother{
    display: flex;
    width: 100%;
    text-align: center;
    span{
      flex: 1;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #C6824E;
    }
  }
}

</style>
