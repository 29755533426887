<template>
  <div id="register">
    <!-- <h1>这是注册账号组件</h1> -->
    <el-breadcrumb>
      <el-breadcrumb-item :class="'active'">{{$t('m.loginandregister.register_account')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div id="input">
      <div id="idinput">
        <span class="name">{{$t('m.loginandregister.Company_Name')}}</span>
        <el-input v-model="inputvalue.realName" maxlength="16" :placeholder="$t('m.loginandregister.Please_enter_Company_Name')">

        </el-input>
      </div>
      <div id="idinput">
        <span class="name">{{$t('m.loginandregister.Contact')}}</span>
        <el-input v-model="inputvalue.contacts" maxlength="16" :placeholder="$t('m.loginandregister.Please_enter_Contact')">
        </el-input>
      </div>
      <div id="idinput">
        <span class="name">{{$t('m.loginandregister.phone_number')}}</span>
        <el-input

        v-model="inputvalue.phoneinput" :placeholder="$t('m.loginandregister.Please_enter_phone_number')">
          <template #append>
            <el-button type="warning" size="small" :loading="checkCodeBtn.loading"
            :disabled="checkCodeBtn.disabled"
            @click="getCheckCode">
            {{checkCodeBtn.text}}</el-button>
            </template>
        </el-input>
      </div>
      <div id="codeinput">
        <span class="name">{{$t('m.loginandregister.auth_code')}}</span>
        <el-input
        maxlength="6"
        minlength="6"

         v-model="inputvalue.codeinput" :placeholder="$t('m.loginandregister.Please_enter_auth_code')" />
        <!-- <i class="icon"></i> -->
      </div>
      <div id="pwdinput">
        <span class="name">{{$t('m.loginandregister.Set_Password')}}</span>
        <el-input
        maxlength="16"
        v-model="inputvalue.pwdinput" show-password :placeholder="$t('m.loginandregister.Please_set_let_num')" />
        <!-- <i class="icon"></i> -->
      </div>
      <div id="confirmpwd_input">
        <span class="name">{{$t('m.loginandregister.Confirm_Password')}}</span>
        <el-input
        maxlength="16"
        v-model="inputvalue.confirmpwd_input" show-password :placeholder="$t('m.loginandregister.Please_set_let_num')" />
      </div>
      <div id="idinput">
        <span class="name">{{$t('m.loginandregister.account_type')}}</span>
        <el-radio-group v-model="inputvalue.preSelectedIdentity">
          <el-radio :label="0" size="small">{{$t('m.loginandregister.buyer')}}</el-radio>
          <el-radio :label="1" size="small">{{$t('m.loginandregister.seller')}}</el-radio>
        </el-radio-group>
      </div>
      <div id="sub">
          <div class="re_suncc">
              <el-checkbox  v-model="checkedRegiste" size="mini">
                {{$t('m.loginandregister.Read_and_agree')}}
              </el-checkbox>
              <span class="xieyi" @click="readContract(1)">《{{$t('m.loginandregister.User_registration_protocol')}}》</span>
              <span class="xieyi" @click="readContract(2)">《{{$t('m.loginandregister.privacy_policy')}}》</span>
          </div>
          <el-button
          native-type="submit" :disabled="!checkedRegiste"
          @click="submitRegister()">{{$t('m.loginandregister.Register_Now')}}</el-button>
      </div>
    </div>
    <div id="toother">
      <span>
        <a @click="commitfather('IDLogin')">{{$t('m.loginandregister.Registered_to_login')}}</a>
      </span>
    </div>
  </div>
</template>

<script>
import { reactive, getCurrentInstance, ref } from 'vue'
import LogRegMain from '../LogRegMain.js'
import tomessage from '../../../tools/tomessage.js'
import http from '../../../api/http.js'
import { ElMessage } from 'element-plus'
import i18n from '@/tools/i18n.js'

export default {
  name: 'Register',
  data () {
    return {
      inputvalue: reactive({
        realName: '', // 企业全称
        contacts: '', // 联系人
        preSelectedIdentity: null, // 0-买家；1-卖家
        phoneinput: '',
        codeinput: '',
        pwdinput: '',
        confirmpwd_input: ''
      })
      // phoneIsNew: ref(true)
    }
  },
  methods: {
    readContract (code) {
      const fileUrl = code === 1
        ? 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/%E7%94%A8%E6%88%B7%E6%B3%A8%E5%86%8C%E5%8D%8F%E8%AE%AE.pdf'
        : 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96.pdf'
      window.open(fileUrl)
    }
  },
  setup (props, ctx) {
    const checkedRegiste = ref(false)

    const datcop = getCurrentInstance()

    const { t } = i18n.global
    // console.log()

    // 验证码获取倒计时操作
    const checkCodeBtn = reactive({
      text: t('m.loginandregister.TestGetCode'),
      loading: false,
      disabled: false,
      duration: 60,
      timer: null
    })

    // 根据用户名获取验证码
    const getCheckCode = () => {
      if (tomessage.fromvalidate('Phone', datcop.data.inputvalue.phoneinput).data.type === 'success') {
        http.get('/mall-portal/sso/getAuthCode', {
          telephone: String(datcop.data.inputvalue.phoneinput),
          flag: 0,
          type: 'null'
        })
          .then(res => {
            console.log(res)
            if (res.data.code === 200) {
              ElMessage.success({ message: res.data.message })
            }
            if (res.data.message === '该手机已注册过了') {
              ElMessage.error({
                message: res.data.message
              })
              clearInterval(checkCodeBtn.timer)
            }
          }).catch(err => {
            console.log(err)
          })
        // 倒计时期间按钮不能单击
        if (checkCodeBtn.duration !== 60) {
          checkCodeBtn.disabled = true
        }
        // 清除掉定时器
        checkCodeBtn.timer && clearInterval(checkCodeBtn.timer)
        // 开启定时器
        checkCodeBtn.timer = setInterval(() => {
          const tmp = checkCodeBtn.duration--
          checkCodeBtn.text = `${tmp}${t('m.loginandregister.second')}`
          if (tmp <= 0) {
          // 清除掉定时器
            clearInterval(checkCodeBtn.timer)
            checkCodeBtn.duration = 60
            checkCodeBtn.text = t('m.loginandregister.regain')
            // 设置按钮可以单击
            checkCodeBtn.disabled = false
            ElMessage.warning({ message: t('m.loginandregister.Please_code_again') })
          }
        // console.log(checkCodeBtn.duration)
        }, 1000)
      } else {
        ElMessage.error({ duration: 1000, showClose: true, message: t('m.loginandregister.phonenumber_incorrect') })
      }
    }
    // 父元素LogRegMain 控制跳转逻辑
    const commitfather = (pageName) => {
      ctx.emit('topage', pageName)
    }
    // 把数据传给数据处理方法LogRegMian
    const verify = (value, type, confirmpwd) => {
      if (confirmpwd === 'confirmpwd_input') {
        LogRegMain.setmessage.ConfirmPwd(value, type)
      }
      switch (type) {
        case 'phone':
          LogRegMain.setmessage.Phone(value)
          break
        case 'password':
          LogRegMain.setmessage.Password(value)
          break
        case 'Authcode':
          LogRegMain.setmessage.AuthCode(value)
          break
        default:
          return false
      }
    }
    const submitRegister = () => {
      // console.log(...toRefs(datcop.data.inputvalue))
      // console.log(datcop.data.inputvalue)
      const pardata = {
        type: 0,
        id: null,
        authCode: datcop.data.inputvalue.codeinput,
        phone: datcop.data.inputvalue.phoneinput,
        password: datcop.data.inputvalue.pwdinput,
        preSelectedIdentity: datcop.data.inputvalue.preSelectedIdentity, // 账户注册类型
        contacts: datcop.data.inputvalue.contacts, // 联系人
        realName: datcop.data.inputvalue.realName // 企业全称
      }
      console.log(pardata)
      if (datcop.data.inputvalue.preSelectedIdentity == null) {
        ElMessage.warning({
          message: '请选择注册账户类型',
          offset: 150,
          duration: 3000
        })
        return
      }
      if (datcop.data.inputvalue.contacts === '') {
        ElMessage.warning({
          message: '联系人不能为空',
          offset: 150,
          duration: 3000
        })
        return
      }
      if (datcop.data.inputvalue.realName === '') {
        ElMessage.warning({
          message: '企业全称不能为空',
          offset: 150,
          duration: 3000
        })
        return
      }
      if (tomessage.fromvalidate('Phone', pardata.phone).data.type === 'success') {
        if (pardata.authCode !== '') {
          if (tomessage.fromvalidate('Password', pardata.password).data.type === 'success') {
            if (datcop.data.inputvalue.confirmpwd_input === pardata.password & pardata.password !== '') {
              if (!checkedRegiste.value) {
                ElMessage.warning({
                  message: '请勾选并阅读用户注册协议',
                  offset: 150,
                  duration: 3000
                })
                return
              }
              http.post('/mall-portal/sso/register', pardata, false)
                .then(res => {
                  console.log(res)
                  if (res.data.code === 200) {
                    ElMessage.success({ duration: 1500, showClose: true, message: res.data.message })
                    setTimeout(() => {
                      commitfather('IDLogin')
                      clearInterval(checkCodeBtn.timer)
                    }, 1000)
                  } else {
                    ElMessage.warning({
                      message: res.data.message,
                      offset: 150,
                      duration: 3000
                    })
                  }
                }).catch(err => {
                  ElMessage.error({ duration: 1500, showClose: true, message: err })
                })
            } else {
              if (pardata.password === '') {
                ElMessage.error({
                  message: '密码不能为空',
                  offset: 150,
                  duration: 3000
                })
              } else {
                ElMessage.error({
                  message: '密码不一致',
                  offset: 150,
                  duration: 3000
                })
              }
            }
          } else {
            if (!pardata.password) {
              ElMessage.error({
                message: '密码不能为空',
                type: 'error',
                offset: 150,
                duration: 3000
              })
            } else if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/.test(pardata.password)) {
              ElMessage.error({
                message: '请正确填写密码（含有数字和字母，且长度要在8-16位之间）',
                type: 'error',
                offset: 150,
                duration: 3000
              })
            }
          }
        } else {
          ElMessage.error({
            message: '请输入验证码',
            offset: 150,
            duration: 3000
          })
        }
      }
    }

    return {
      checkCodeBtn, checkedRegiste, commitfather, getCheckCode, verify, submitRegister
    }
  }
}
</script>

<style lang="scss">
.re_suncc .el-checkbox .is-checked .el-checkbox__inner {
  background-color: #C6824E;
  border: 1px solid #C6824E;
}
.re_suncc .el-checkbox .is-checked .el-checkbox__inner::after {
  border-color: #ffffff;
}
.re_suncc .el-checkbox .is-checked .el-checkbox__inner span {
  color: #C6824E;
}
</style>
<style lang="scss">
@import '../../../style/reglog.scss';
@import '../../../style/base.css';
#register{
  // background: palegreen;
  min-height: 420px;
  box-sizing: border-box;
  margin: 40px;
  display: flex;
  flex-wrap: wrap;
  .el-breadcrumb{
    padding: 8px 40px;
    width: 100%;
    .el-breadcrumb__item{
      text-align: center;
      width: 100%;
      .el-breadcrumb__inner{
        color: #999999;
        font-size: 20px;
        // cursor: pointer;
      }
      .el-breadcrumb__separator{
        // display: block;
        margin:0 35px;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .active{
      .el-breadcrumb__inner{
        color: #333333;
      }
    }
  }
  #input{
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
    #idinput,#codeinput,#pwdinput,#confirmpwd_input{
      margin: 10px 0;
      height: 30px;
      line-height: 30px;
      width: 100%;
      display: flex;
      border-bottom: 1px solid #EAEAEA;
      .name{
        height: 30px;
        line-height: 30px;
        // width: 0px;
        min-width: 80px;
        font-size: 14px;
        flex-shrink: 0;
      }
      .el-input{
        .el-input__suffix{
          .el-input__suffix-inner{
            .el-input__icon{
              color: #333333;
            }
          }
        }
        .el-input-group__append{
          border: 0;
          background: none;
          .el-button--warning{
            padding:0;
            height: 30px;
            color: #333333;
            text-align: right;
            span{
              text-align: center;
              display: inline-block;
              padding: 0 18px;
              width: 60px;
              border-left: #999999 1px solid;
            }
          }
        }
      }
      input{
        height: 30px;
        flex: 1;
      }
      .icon{
        border: 1px solid black;
        margin: 0 10px;
        width: 30px;
      }
    }
    #sub{
      width: 100%;
      display: inline-block;
      // margin-top: 10px;
      .re_suncc{
        // line-height: 15px;
        // width: 100%;
        // max-width: 370px;
        display: flex;
        flex-wrap: wrap;
        // flex-direction: column;
        // align-items: center;
        // justify-content: center;
        .xieyi{
          cursor: pointer;
          // max-width: 210px;
          height: 30px;
          line-height: 30px;
          color: #C6824E;
        }
        .el-checkbox{
          // width: 100%;
          .el-checkbox__input{
            background:#C6824E ;
          }
          .el-checkbox__label{
            color: #333333;
            word-break:break-all;
          }
          .is-checked{
            color: #C6824E;
          }
        }

      }
      button{
        width: 100%;
        height: 40px;
        background: linear-gradient(0deg, #280E01 0%, #673000 100%);
        span{
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
  #toother{
    display: flex;
    width: 100%;
    text-align: center;
    span{
      flex: 1;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #C6824E;
    }
  }
}

</style>
