
<template>
  <div id="page">
    <img class="bgimg" src="../../static/imges/LRbg.jpg" alt="">
    <div id="main">
      <div id="show">
        <!-- <keep-alive> -->
        <component :is="isPage"
        @topage="(data)=>isPage=data"></component>
        <!-- </keep-alive> -->
        <!-- <button @click="chosepage('PhoneLogin')">PhoneLogin</button>
        <button @click="chosepage('Register')">Register</button>
        <button @click="chosepage('ResetPwd')">ResetPwd</button>
        <button @click="chosepage('RetrievePwd')">RetrievePwd</button> -->
      </div>
    </div>
  </div>
  <div id="footer">
    <keep-alive>
      <Footer/>
    </keep-alive>
  </div>
</template>

<script>
import Footer from '../index/Footer.vue'

import IDLogin from './login/IDLogin'
import PhoneLogin from './login/PhoneLogin'
import Register from './register/Register'
import ResetPwd from './ResetPwd'
import RetrievePwd from './RetrievePwd'

export default {
  name: 'LogRegMain',
  // inject: ['reload'],
  data () {
    return {
      isPage: 'IDLogin'
    }
  },
  components: {
    Footer, Register, ResetPwd, RetrievePwd, IDLogin, PhoneLogin
  },
  methods: {
    // chosepage (k) {
    //   return (this.isPage = k)
    // }
    // pageName () {
    //   this.reload()
    //   return this.isPage
    // }
  }
}
</script>

<style lang="scss" scoped>
@import '../../style/base.css';
@import '../../style/reglog.scss';
#page{
  position:absolute;
  width: 100%;
  height: 100%;
  // background: url('../../static/imges/LRbg.jpg');
  display:flex;
  justify-content:center;
  align-items: center;
  flex-flow: column;
  .bgimg{
    position: absolute;
    width: 100%;
    height: 100%;
  }
  #main{
    z-index: 10;
    width: 460px;
    // min-width: 460px;
    background: #FFFFFF;
    box-sizing: border-box;
  }
}
#footer{
  width: 100%;
  position: absolute;
  bottom: 1px;
}
</style>
