<template>
  <div id="phonelogin">
    <!-- <h1>这是手机号登录组件</h1> -->
    <el-breadcrumb separator="|">
      <el-breadcrumb-item @click="commitfather('IDLogin')">账号登录</el-breadcrumb-item>
      <el-breadcrumb-item :class="'active'">手机登录</el-breadcrumb-item>
    </el-breadcrumb>
    <div id="input">
      <div id="idinput">
        <span class="name">手机号</span>
        <!-- <input id="username" type="text" placeholder="请输入手机号"> -->
        <el-input @blur="verify(inputvalue.phoneinput,'phone')"
        v-model="inputvalue.phoneinput" placeholder="请输入手机号">
          <template #append>
            <el-button type="warning" size="small"
            :loading="checkCodeBtn.loading"
            :disabled="checkCodeBtn.disabled"
            @click="getCheckCode">
            {{checkCodeBtn.text}}</el-button>
            </template>
        </el-input>
      </div>
      <div id="codeinput">
        <span class="name">验证码</span>
        <!-- <input id="username" type="text" placeholder="请输入验证码"> -->
        <el-input v-model="inputvalue.codeinput" placeholder="请输入验证码" />
        <!-- <i class="icon"></i> -->
      </div>
      <div id="sub">
          <el-button style="color: #ffffff"
          native-type="submit" @click="submitLogin">立即登录</el-button>
      </div>
    </div>
    <div id="toother">
      <span class="left"><a @click="commitfather('Register')">免费注册</a></span>
      <span class="right"><a @click="commitfather('RetrievePwd')">忘记密码</a></span>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, reactive } from 'vue'
import LogRegMain from '../LogRegMain'
import http from '../../../api/http'
import tomessage from '../../../tools/tomessage.js'

import { ElMessage } from 'element-plus'
import ck from '../../../tools/cookie'
import { setStore } from '../../../tools/storage'
import { useRouter } from 'vue-router'
export default {
  name: 'PhoneLogin',
  data () {
    return {
      inputvalue: reactive({
        phoneinput: '',
        codeinput: ''
      })
    }
  },
  methods: {
  },
  setup (props, context) {
    const datcop = getCurrentInstance()
    const router = useRouter()
    // 验证码获取倒计时操作
    const checkCodeBtn = reactive({
      text: '获取验证码',
      loading: false,
      disabled: false,
      duration: 60,
      timer: null
    })
    // 根据用户名获取验证码
    const getCheckCode = () => {
      http.get('/mall-portal/sso/getAuthCode', {
        telephone: String(datcop.data.inputvalue.phoneinput),
        flag: 0,
        type: 'null'
      })
        .then(res => {
          console.log(res)
          if (res.data.code === 200) {
            ElMessage.success({ message: res.data.message })
          }
          if (res.data.message === '该手机已注册过了') {
            clearInterval(checkCodeBtn.timer)
          }
        }).catch(err => {
          console.log(err)
        })
      // 倒计时期间按钮不能单击
      if (checkCodeBtn.duration !== 60) {
        checkCodeBtn.disabled = true
      }
      // 清除掉定时器
      checkCodeBtn.timer && clearInterval(checkCodeBtn.timer)
      // 开启定时器
      checkCodeBtn.timer = setInterval(() => {
        const tmp = checkCodeBtn.duration--
        checkCodeBtn.text = `${tmp}秒`
        if (tmp <= 0) {
          // 清除掉定时器
          clearInterval(checkCodeBtn.timer)
          checkCodeBtn.duration = 60
          checkCodeBtn.text = '重新获取'
          // 设置按钮可以单击
          checkCodeBtn.disabled = false
        }
        // console.log(checkCodeBtn.duration)
      }, 1000)
    }
    // 把跳转的页面传值给（父）主页面
    const commitfather = (pageName) => {
      context.emit('topage', pageName)
    }
    // 把数据传给数据处理方法LogRegMian
    const verify = (value, type) => {
      if (type === 'phone') {
        LogRegMain.setmessage.Phone(value)
      } else {
        console.error('找不到匹配内容')
      }
    }
    const submitLogin = () => {
      const pardata = {
        phone: datcop.data.inputvalue.idinput,
        authCode: datcop.data.inputvalue.codeinput
      }
      // console.log(CK.getCookie('token'))
      if (tomessage.fromvalidate('Phone', pardata.phone).data.type === 'success') {
        http.post('/mall-portal/sso/authCodeLongin', { username: pardata.phone, authCode: pardata.authCode }, false)
          .then(res => {
            console.log(res)
            switch (res.data.code) {
              case 200:
                // ElMessage.success({ message: '登录成功！！' })
                ck.setCookie('token', res.data.data.token, 1)
                saveUser(res.data.data)
                break
              case 500:
                break
              default:
                break
            }
          }).catch(e => {
            // console.log(e)
          })
      } else {
        if (pardata.phone === '' || pardata.authCode === '') {
          ElMessage.error({ duration: 1000, showClose: true, message: '手机号或验证码不能为空' })
        } else {
          ElMessage.error({ duration: 1000, showClose: true, message: '手机号不正确' })
        }
      }
    }
    const saveUser = (token) => {
      console.log(token)
      // console.log(CK)
      ElMessage({ duration: 1500, message: '登录成功！！', type: 'success' })

      http.get('/mall-portal/sso/info')
        .then(res => {
          console.log(res)
          const User = {
            id: res.data.data.id,
            nickname: res.data.data.realName,
            avatar: res.data.data.icon
          }
          setStore('User', User)
          setStore('usermessage', res.data.data) // 存个人信息
          router.push('/')
          const timeer = setInterval(() => {
            window.location.reload()
            clearInterval(timeer)
          }, 300)
          timeer()
        }).catch(err => {
          console.log(err)
        })
    }
    return {
      checkCodeBtn, commitfather, getCheckCode, verify, submitLogin
    }
  }
}
</script>

<style lang="scss">
@import '../../../style/reglog.scss';
@import '../../../style/base.css';
#phonelogin{
  // background: palegreen;
  height: 320px;
  box-sizing: border-box;
  margin: 40px;
  display: flex;
  flex-wrap: wrap;
  .el-breadcrumb{
    padding: 8px 40px;
    width: 100%;
    .el-breadcrumb__item{
      // font-size: 20px;
      .el-breadcrumb__inner{
        color: #999999;
        font-size: 20px;
        cursor: pointer;
      }
      .el-breadcrumb__separator{
        // display: block;
        margin:0 35px;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .active{
      .el-breadcrumb__inner{
        color: #333333;
      }
    }
  }
  #input{
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
    #idinput,#codeinput{
      margin: 15px 0;
      width: 100%;
      display: flex;
      border-bottom: 1px solid #EAEAEA;
      .name{
        height: 30px;
        line-height: 30px;
        width: 60px;
        font-size: 14px;
        flex-shrink: 0;
      }
      .el-input{
        .el-input__suffix{
          .el-input__suffix-inner{
            .el-input__icon{
              color: #333333;
            }
          }
        }
        .el-input-group__append{
          border: 0;
          background: none;
          .el-button--warning{
            padding:0;
            height: 30px;
            color: #333333;
            text-align: right;
            span{
              text-align: center;
              display: inline-block;
              padding: 0 18px;
              width: 70px;
              border-left: #999999 1px solid;
            }
          }
        }
      }
      input{
        height: 30px;
        flex: 1;
      }
      .icon{
        border: 1px solid black;
        margin: 0 10px;
        width: 30px;
      }
    }
    #sub{
      width: 100%;
      margin: 30px 0;
      button{
        width: 100%;
        height: 40px;
        background: linear-gradient(0deg, #280E01 0%, #673000 100%);
        span{
          font-weight: 400;
        }
      }
    }
  }
  #toother{
    display: flex;
    width: 100%;
    span{
      flex: 1;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
    .left{
      text-align: left;
    }
    .right{
      text-align: right;
    }
  }
}

</style>
